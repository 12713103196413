import {Button, Link, Paper, Typography} from '@mui/material'
import {useNavigate} from 'react-router'

const Impressum = () => {
    const navigate = useNavigate()
    return (
        <Paper sx={{m:2, p:2}}>
            <Button onClick={() => navigate(-1)}>🠔 Zurück</Button>
            <Typography variant="h3" component="h1" gutterBottom>Impressum</Typography>

            <Typography variant="h5" component="h2" gutterBottom>Angaben gem&auml;&szlig; &sect; 5 TMG</Typography>
            <Typography variant="body" component="p" gutterBottom>
                Manuel Richarz<br />
                IT-Dienstleistungen Manuel Richarz<br />
                Pfarrstr. 35<br />
                76189 Karlsruhe
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>Kontakt</Typography>
            <Typography variant="body" component="p" gutterBottom>
                Telefon: 017682122939<br />
                E-Mail: webmaster@nysoft.de
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>Umsatzsteuer-ID</Typography>
            <Typography variant="body" component="p" gutterBottom>
                Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                DE261929410
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>EU-Streitschlichtung</Typography>
            <Typography variant="body" component="p" gutterBottom>
                Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <Link href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</Link>.<br />
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Typography>
            <Typography variant="body" component="p" gutterBottom>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </Typography>

            <Typography variant="body" component="p" gutterBottom>Quelle: <Link href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer">e-recht24.de</Link></Typography>
        </Paper>
    )
}

export default Impressum