import {useState, useEffect, useCallback} from 'react'
import {Box, Typography} from "@mui/material"
import {useNavigate} from 'react-router'
import URLInput from "../Molecules/URLInput"
import axios from "axios";

const New = () => {
    const [url, setUrl] = useState('')
    const [ttl, setTtl] = useState(86400 * 90)
    const [error, setError] = useState('')
    const navTo = useNavigate()

    const handleUrlChange = (e) => {
        setUrl(e.target.value)
        setError('')
    }
    const handleTtlChange = (e) => {
        setTtl(e.target.value)
    }

    const handleSubmit = async () => {
        try {
            const cleanedURL = new URL(url)
            await createNewMicroURL(cleanedURL, ttl)
        } catch(e) {
            setError('Keine gültige URL!')
        }
    }

    const createNewMicroURL = useCallback(async (url, ttl, message) => {
        // generate MircoURL and navigate to resultpage
        const {data} = await axios.post('/api/v1/url', {
            url,
            ttl,
            message
        })

        navTo(`/${data.hash}/details`, {
            state: data
        })
    },[navTo])

    const pasteEventHandler = useCallback((e) => {
        setUrl(e.clipboardData.getData("text"))
        setError('')
        const cleanedURL = new URL(e.clipboardData.getData("text"))
        createNewMicroURL(cleanedURL, ttl)
    }, [createNewMicroURL, ttl])

    useEffect(() => {
        window.addEventListener("paste", pasteEventHandler)

        return () => {
            window.removeEventListener("paste", pasteEventHandler)
        }
    }, [pasteEventHandler])

    return (
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
            <Box sx={{width: '66vw', display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h5" gutterBottom>Erzeuge kurze und knackige URLs für Briefe, Social Media, Chats, uvm.</Typography>
                <URLInput
                    error={error}
                    defaultUrlValue={url}
                    onUrlChange={handleUrlChange}
                    defaultTtlValue={ttl}
                    onTtlChange={handleTtlChange}
                    onSubmit={handleSubmit}
                />
            </Box>
        </Box>
    )
}

export default New