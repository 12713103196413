import {useEffect, useState} from "react"
import QRCode from 'qrcode'
import {Box, Typography} from "@mui/material"

const QR = ({text, label}) => {
    const [dataUrl, setDataUrl] = useState('')
    useEffect(() => {
        const getDataUrl = async () => {
            setDataUrl(await QRCode.toDataURL(text,{
                errorCorrectionLevel: 'L',
                margin: 4,
                scale: 6,
                color: {
                    dark: "#000000",
                    light: "#FFFFFFD8"
                }
            }))
        }
        getDataUrl()
    }, [text])

    return (
        <Box sx={{ml: 1, p: 1, textAlign: 'center'}}>
            <Typography variant="caption" component="div" gutterBottom>{label}</Typography>
            <img style={{width: '100%'}} src={dataUrl} alt={text} />
        </Box>
    )
}
export default QR