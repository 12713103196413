import {useEffect} from 'react'
import {useLocation} from "react-router-dom"
import {useNavigate} from "react-router"
import {isProductionMode} from "../Utils/env"

const Redirect = () => {
    const navTo = useNavigate()
    const location = useLocation()
    const loc = window.location
    const redirectURL = `${loc.protocol}//${loc.hostname}:3100${location.pathname}`

    useEffect(() => {
        if(redirectURL && !isProductionMode) {
            window.location.href = redirectURL
        } else {
            navTo('/')
        }
    }, [navTo, redirectURL])

    return (
        <></>
    )
}

export default Redirect