import {Link, useLocation} from 'react-router-dom'
import {Box, Button} from '@mui/material'
import {Navigate} from "react-router"
import MicroURL from "../Organisms/MicroURL"

const Details = () => {
    const loc = useLocation()
    const data = loc.state

    if(!data) {
        return <Navigate to="/" replace />
    }

    return (
        <Box display="flex" flexDirection={'column'} flexGrow={1} alignItems="center" justifyContent="center">
            <MicroURL {...data} />
            <Button sx={{mt:6, mb:6}} color="info" variant="contained" component={Link} to={'/'}>Neue MicroURL anlegen</Button>
        </Box>
    )
}

export default Details