import {TextField, Tooltip} from "@mui/material"
import {useState} from "react"
import {copyToClipboard} from "../Utils/clipboard"

const CopyableTextfield = ({value, tooltip, ...props}) => {

    const [open, setOpen] = useState(false)
    const [timer, setTimer] = useState(null)

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleCopyToClipboard = (e) => {
        clearTimeout(timer)
        copyToClipboard(e.target.value)
        setOpen(true)
        setTimer(setTimeout(() => {
            setOpen(false)
        }, 3000))
    }

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={tooltip}
        >
            <TextField
                value={value}
                onClick={handleCopyToClipboard}
                {...props}
            />
        </Tooltip>
    )
}

export default CopyableTextfield