import {Box, Button, Typography} from "@mui/material"
import {useNavigate} from "react-router"
import axios from "axios"
import {useLocation} from "react-router-dom"
import {useEffect} from "react";

const Delete = () => {
    const {search, pathname} = useLocation()
    const hash = pathname.split('/')[1]
    const securetoken = new URLSearchParams(search).get('securetoken')
    const navTo = useNavigate()

    const handleCancel = () => {
        navTo('/')
    }

    const handleDelete = async () => {
        const result = await axios.delete(`/api/v1/url/${hash}`, {
            data: {
                securetoken
            }
        })
        console.log(result)
        navTo('/')
    }

    useEffect(() => {
        if(!hash || !securetoken) {
            navTo('/')
        }
    }, [navTo, hash, securetoken])

    if(!hash || !securetoken) {
        return
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center">
            <Typography variant="h4" component="h2" gutterBottom>
                Möchtest du diese MicroURL wirklich unwideruflich löschen?
            </Typography>
            <Box display="flex" justifyContent="space-between">
                <Button sx={{mr:4}} color="primary" variant="contained" onClick={handleCancel}>Nein</Button>
                <Button color="secondary" variant="contained" onClick={handleDelete}>Ja</Button>
            </Box>
        </Box>
    )
}

export default Delete