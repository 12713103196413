import {Box, Link, Typography} from "@mui/material"
import {makeStyles} from '@mui/styles'
import {Link as NavLink} from 'react-router-dom'
import Version from "../Molecules/Version"

const useStyles = makeStyles((theme) => ({
    rootBox: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
    },
    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

export default function Footer(props) {
    const classes = useStyles()

    const content = {
        brand: { image: '/logo.png', width: 42, text: 'NySoft' },
        copy: `© 2022 NySoft All rights reserved.`,
        links: [
            {
                href: '/datenschutz',
                title: 'Datenschutz'
            },
            {
                href: '/impressum',
                title: 'Impressum'
            }
        ],
        ...props.content
    }

    let brand

    if (content.brand.image) {
        brand = <img src={content.brand.image} alt={content.brand.text} width={content.brand.width} />
    } else {
        brand = content.brand.text || ''
    }

    return (
        <Box component="footer" display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" className={classes.rootBox}>
            <div className={classes.footerNav}>
                <Link sx={{m:2}} component={NavLink} to="/" color="inherit" underline="none">
                    {brand}
                </Link>
                <Typography color="textSecondary" variant="caption">{content.copy}</Typography>
            </div>
            <Version />
            <Box component="nav" className={classes.footerNav}>
            {content.links.map(link =>
                <Link key={link.href} sx={{m: 2}} component={NavLink} to={link.href} variant="body1" color="textPrimary">{link.title}</Link>
            )}
            </Box>
        </Box>
    )
}