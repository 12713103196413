import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {createTheme, CssBaseline, GlobalStyles, ThemeProvider} from '@mui/material'

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#dd040c',
        },
        secondary: {
            main: '#297825',
        },
    },
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <StrictMode>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{
                body: {
                    background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                    backgroundSize: '400% 400%',
                    animation: 'gradient 30s ease infinite',
                    height: '100vh'
                },
                '@keyframes gradient': {
                    '0%': {
                        backgroundPosition: '0% 50%'
                    },
                    '50%': {
                        backgroundPosition: '100% 50%'
                    },
                    '100%': {
                        backgroundPosition: '0% 50%'
                    }
                }
            }}/>
            <App/>
        </ThemeProvider>
    </StrictMode>
)