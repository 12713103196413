import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom"
import New from "./Pages/New"
import Details from "./Pages/Details"
import Delete from "./Pages/Delete"
import Impressum from "./Pages/Impressum"
import Datenschutz from "./Pages/Datenschutz"
import NotFound from "./Pages/NotFound"
import Redirect from "./Pages/Redirect"
import Footer from "./Organisms/Footer"
import {Box} from "@mui/material"

function App() {
  return (
      <Router>
          <Box sx={{minHeight: '100vh', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
              <Routes>
                  <Route path="/">
                      <Route index element={<New/>}/>
                      <Route path=":hash">
                          <Route index element={<Redirect/>}/>
                          <Route path="details" element={<Details/>}/>
                          <Route path="delete" element={<Delete/>}/>
                          <Route path="qr" element={<Redirect/>}/>
                      </Route>
                      <Route path="impressum" element={<Impressum/>}/>
                      <Route path="datenschutz" element={<Datenschutz/>}/>
                  </Route>
                  <Route path="*" element={<NotFound/>}/>
              </Routes>
              <Footer/>
          </Box>
      </Router>
  )
}

export default App;
