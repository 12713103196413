import {Button, Paper, Typography} from '@mui/material'
import {useNavigate} from 'react-router'

const Datenschutz = () => {
    const navigate = useNavigate()
    return (
        <Paper sx={{m:2, p:2}}>
            <Button onClick={() => navigate(-1)}>🠔 Zurück</Button>
            <Typography variant="h3" component="h1" gutterBottom>Datenschutz</Typography>

            <Typography variant="body" component="p" gutterBottom>
                Wir nehmen Datenschutz sehr ernst!<br />
                Daher setzen wir <strong>keine</strong> Tracker oder Cookies ein und es werden auch <strong>keine</strong> IP-Adressen geloggt.<br />
            </Typography>
            <Typography variant="body" component="p" gutterBottom>
                Jede erstellte Micro-URL hat eine Lebensdauer und wird nach Ablauf der Lebensdauer endgültig und <strong>unwideruflich</strong> gelöscht.<br />
                Der Löschlink einer Micro-URL resultiert, nach einer Bestätigung, ebenfalls in einer endgültigen und <strong>unwideruflichen</strong> Löschung der Micro-URL.
            </Typography>
        </Paper>
    )
}

export default Datenschutz