import {MenuItem, TextField, Tooltip} from '@mui/material'
import {isProductionMode} from "../Utils/env"

const TTLSelect = ({onChange, defaultValue, ttl, label='TTL', notifyMessage, textFieldProps}) => {
    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            open={!!notifyMessage}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={notifyMessage}
        >
            <TextField
                variant="standard"
                sx={{minWidth: 100}}
                select
                onChange={onChange}
                defaultValue={defaultValue}
                value={ttl}
                label={label}
                {...textFieldProps}
            >
                {!isProductionMode && <MenuItem value={5}>5 Sekunden</MenuItem>}
                {!isProductionMode && <MenuItem value={60}>1 Minute</MenuItem>}
                <MenuItem value={86400}>1 Tag</MenuItem>
                <MenuItem value={86400 * 7}>7 Tage</MenuItem>
                <MenuItem value={86400 * 30}>1 Monat</MenuItem>
                <MenuItem value={86400 * 90}>3 Monate</MenuItem>
                <MenuItem value={86400 * 365}>1 Jahr</MenuItem>
                <MenuItem value={86400 * 365 * 3}>3 Jahre</MenuItem>
                <MenuItem value={86400 * 365 * 10}>10 Jahre</MenuItem>
            </TextField>
        </Tooltip>
    )
}

export default TTLSelect