import {IconButton, Paper, TextField} from "@mui/material"
import CompressIcon from '@mui/icons-material/Compress'
import TTLSelect from "./TTLSelect"

const URLInput = ({error, name, defaultUrlValue, url, onUrlChange, defaultTtlValue, ttl, onTtlChange, onSubmit, textFieldProps, buttonProps}) => {
    return (
        <Paper
            component="form"
            sx={{p:1, display: 'flex', width: '100%', backgroundColor: 'rgba(255,255,255,0.85)' }}
        >
            <TextField
                autoFocus
                name={name}
                label="Ziel-URL"
                placeholder="Füge hier deine Ziel-URL ein"
                value={url}
                defaultValue={defaultUrlValue}
                onChange={onUrlChange}
                fullWidth
                variant="standard"
                error={!!error}
                helperText={error}
                {...textFieldProps}
            />
            <TTLSelect onChange={onTtlChange} defaultValue={defaultTtlValue} value={ttl} />
            <IconButton title="URL jetzt schrumpfen" size="large" onClick={onSubmit} {...buttonProps}>
                <CompressIcon />
            </IconButton>
        </Paper>
    )
}

export default URLInput